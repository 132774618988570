import { H, Section } from "@jfrk/react-heading-levels";
import cx from "classnames";
import { useStaticQuery, graphql } from "gatsby";
import React from "react";

import {
  HeaderSearch,
  HeaderShortcuts,
  HeaderFrontPageMenu,
} from "../components/Header";
import Layout from "../components/Layout/Layout";
import ModuleArea from "../components/ModuleArea/ModuleArea";
import SEO from "../components/seo";
import { useIsMultisite } from "../hooks/multisite";
import { PageContextProvider } from "../hooks/pageContext";
import usePages from "../hooks/pages";
import { RekaiTrack } from "../hooks/rekai";
import { getPageThemeColor } from "../utils/pageTree";

export default function StartPageTemplate({ pageContext }) {
  const {
    page: {
      id: pageId,
      databaseId,
      title,
      modularityModules: { contentArea, sliderArea } = {},
    },
    isPreview,
  } = pageContext;
  const allPages = usePages();
  const isMultisite = useIsMultisite();

  const siteTitle = useStaticQuery(graphql`
    query MyQuery2 {
      site {
        siteMetadata {
          title
        }
      }
    }
  `).site.siteMetadata.title;

  return (
    <PageContextProvider
      value={{
        ...pageContext,
        postId: databaseId,
        postTypeName: "page",
        template: "startPage",
      }}
    >
      <Layout
        theme={getPageThemeColor(allPages, pageId)}
        header={
          !isMultisite && (
            <>
              <HeaderSearch />
              <HeaderShortcuts />
              <HeaderFrontPageMenu />
            </>
          )
        }
        hideSearchForm={!isMultisite}
        sliderArea={sliderArea}
      >
        <SEO title={title} />
        {!isMultisite && !isPreview && <RekaiTrack />}
        <H className="u-visually-hidden">{siteTitle}</H>
        <Section>
          <div className="o-grid-row o-margin-top-large">
            {contentArea && <ModuleArea area={contentArea} />}
          </div>
        </Section>
      </Layout>
    </PageContextProvider>
  );
}
